import isString from "lodash/isString";
import padStart from "lodash/padStart";
import trans from "@/helpers/trans";

const MONTHS = [
	trans("Jan"),
	trans("Feb"),
	trans("Mar"),
	trans("Apr"),
	trans("May"),
	trans("Jun"),
	trans("Jul"),
	trans("Aug"),
	trans("Sep"),
	trans("Oct"),
	trans("Nov"),
	trans("Dec"),
];

/*
// Original function:
new Date(date).toLocaleDateString("en-us", {
	year: "numeric",
	month: "short",
	day: "numeric",
});

// Short:
new Date(date).toLocaleDateString();
*/
export default function formatDate(date, short = false) {
	if (isString(date)) {
		const match = date.match(/^(\d\d\d\d)-(\d\d)-(\d\d)/);
		if (match.length === 4) {
			const year = parseInt(match[1]);
			const month = parseInt(match[2]) - 1;
			if (month >= 0 && month <= 11) {
				const date = parseInt(match[3]);
				if (short) {
					return `${year}-${padStart((month + 1).toString(), 2, "0")}-${padStart(date.toString(), 2, "0")}`;
				} else {
					return `${MONTHS[month]} ${date}, ${year}`;
				}
			}
		}
	}

	return null;
}

import React from "react";
import slugify from "slugify";
import { tailwindCascade } from "@/helpers/tailwindCascade";

export default function NavigationCategoryIcon({ name, className }) {
	if (!name) {
		return null;
	}

	const slug = slugify(name, { lower: true, strict: true });
	const src = `/images/icons/navigation/${slug}.svg`;
	const alt = name;

	return <img src={src} alt={alt} draggable={false} className={tailwindCascade("w-9", "h-9", className)} />;
}

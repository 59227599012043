import React from "react";
import Link from "next/link";

import trans from "@/helpers/trans";
import { tailwindCascade } from "@/helpers/tailwindCascade";

import DiscordButton from "@/components/interactives/DiscordButton";

import { DISCORD_LINK } from "@/constants";

export default function Footer({ className, showBigDiscordButton, ...props }) {
	return (
		<footer className={tailwindCascade("pb-safe", className)} {...props}>
			{showBigDiscordButton && <DiscordButton className="mb-2" />}

			<div className="text-sans opacity-60 py-4 text-sm text-black">
				<span className="pr-1">&copy;</span>
				<span className="hover:underline pr-2">
					<a href="https://oldapes.com/" target="_blank" rel="noreferrer" title={trans("Old Apes")}>
						{trans("Old Apes")}
					</a>
				</span>
				<span className="pr-2">&bull;</span>
				<span className="hover:underline pr-2">
					<Link legacyBehavior href="mailto:info@quiz.com" prefetch={false}>
						<a title={trans("Contact")}>{trans("Contact")}</a>
					</Link>
				</span>
				<span className="pr-2">&bull;</span>
				<span className="hover:underline pr-1">
					<Link legacyBehavior href="/terms/" prefetch={false}>
						<a title={trans("Terms")}>{trans("Terms")}</a>
					</Link>
				</span>
				<span className="hover:underline pr-1">&amp;</span>
				<span className="hover:underline pr-2">
					<Link legacyBehavior href="/privacy/" prefetch={false}>
						<a title={trans("Privacy")}>{trans("Privacy")}</a>
					</Link>
				</span>
				<span className="pr-2">&bull;</span>
				<span className="hover:underline pr-2">
					<Link legacyBehavior href="https://status.quiz.com/" prefetch={false} target="_blank">
						<a title={trans("Status")}>{trans("Status")}</a>
					</Link>
				</span>
				{!showBigDiscordButton && (
					<>
						<span className="pr-2">&bull;</span>
						<span className="hover:underline pr-1">
							<Link legacyBehavior href={DISCORD_LINK} prefetch={false}>
								<a title={trans("Join our Discord")}>{trans("Join our Discord")}</a>
							</Link>
						</span>
					</>
				)}
			</div>
		</footer>
	);
}

import React, { memo, useEffect } from "react";
import Link from "next/link";

import isEqual from "lodash/isEqual";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import NavigationCategoryIcon from "@/components/NavigationCategoryIcon";
import trans from "@/helpers/trans";
import { CATEGORIES } from "@/app-constants.mjs";
import useLayoutStore from "@/stores/layout";

function LinkItem({ href, name, selected }) {
	return (
		<Link legacyBehavior href={href} prefetch={false}>
			<a className="whitespace-nowrap group font-roboto md:flex-col md:space-x-0 flex flex-row items-center space-x-3">
				<NavigationCategoryIcon className="md:w-9 md:h-9 w-5 h-5" name={name} />
				<div
					className={tailwindCascade(
						"pb-1 md:text-xs text-base font-bold leading-snug transition-opacity group-hover:opacity-100 opacity-60",
						{
							"opacity-100": selected,
						}
					)}
				>
					{name}
				</div>
				<div
					className={tailwindCascade(
						"w-full group-hover:opacity-100 opacity-0 transition-opacity h-1 bg-black rounded-full hidden md:block",
						{
							"opacity-100": selected,
						}
					)}
				></div>
			</a>
		</Link>
	);
}

export default memo(function NavigationCategories({
	category: selectedCategorySlug,
	isIndexPage = false,
	className,
	...props
}) {
	const setLayoutStore = useLayoutStore((state) => state.set);

	useEffect(
		// close menu when category changes
		() => () => setLayoutStore((draft) => void (draft.mobileNavigationCategoriesVisible = false)),
		[setLayoutStore, selectedCategorySlug]
	);

	return (
		<div
			className={tailwindCascade(
				"md:flex-row md:space-y-0 flex flex-col justify-between w-full space-y-2 font-sans text-base text-left text-black",
				className
			)}
			{...props}
		>
			<LinkItem href="/" name={trans("Start")} selected={isIndexPage} />
			{CATEGORIES.map((category, i) => (
				<LinkItem
					key={i}
					href={`/${category.slug}/`}
					name={category.name}
					selected={selectedCategorySlug === category.slug}
				/>
			))}
		</div>
	);
},
isEqual);

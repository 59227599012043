import Link from "next/link";
import trans from "@/helpers/trans";
import { tailwindCascade } from "@/helpers/tailwindCascade";

import { DISCORD_LINK } from "@/constants";

export default function DiscordButton({ className, ...props }) {
	return (
		<div className={tailwindCascade("w-48", "md:w-96", className)} {...props}>
			<Link legacyBehavior href={DISCORD_LINK} prefetch={false}>
				<a title={trans("Join our Discord")}>
					<img
						src="/images/logo/discord-multicolor.svg"
						width="192"
						height="86"
						alt={trans("Join our Discord")}
						draggable={false}
						className="block w-full h-auto"
					/>
				</a>
			</Link>
		</div>
	);
}

import isFinite from "lodash/isFinite";
import isObject from "lodash/isObject";
import { EASY_THRESHOLD, HARD_THRESHOLD, MIN_NUM_DIFFICULTY } from "@/app-constants.mjs";

export const getDifficulty = (obj, slideCount = 1) => {
	const { numCorrectlyAnswered, numIncorrectlyAnswered, numUnanswered } = isObject(obj) ? obj : {};

	const difficulty =
		isFinite(numCorrectlyAnswered) &&
		isFinite(numIncorrectlyAnswered) &&
		isFinite(numUnanswered) &&
		isFinite(slideCount) &&
		numCorrectlyAnswered + numIncorrectlyAnswered + numUnanswered >= slideCount * MIN_NUM_DIFFICULTY
			? 1 - numCorrectlyAnswered / (numCorrectlyAnswered + numIncorrectlyAnswered + numUnanswered)
			: undefined;

	const isEasy = isFinite(difficulty) && difficulty < EASY_THRESHOLD;
	const isHard = isFinite(difficulty) && difficulty >= HARD_THRESHOLD;

	return { difficulty, isEasy, isHard };
};

import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useRouter } from "next/router";

import { apiEditorialByCategory2 } from "@/api/editorial";
import { getUserSelf } from "@/api/user";

import Editorial from "@/components/Editorial";
import NavigationCategories from "@/components/NavigationCategories";
import NavigationLayout from "@/components/NavigationLayout";
import Button from "@/components/interactives/Button";
import SignUpSignIn from "@/components/interactives/SignUpSignIn";

import trans from "@/helpers/trans";

import useIsomorphicLayoutEffect from "@/hooks/useIsomorphicLayoutEffect";
import usePlayQuiz from "@/hooks/usePlayQuiz";
import useRefCallback from "@/hooks/useRefCallback";
import useRefImmer from "@/hooks/useRefImmer";

import useAuthStore from "@/stores/auth";
import usePlaySessionStore from "@/stores/playSession";
import useWebSocketStore from "@/stores/webSocket";

export default function IndexPage({ ...props }) {
	const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
	const user = useAuthStore((state) => state.user);
	const adminFeaturesEnabled = useAuthStore((state) => state.adminFeaturesEnabled);

	const updateUser = useAuthStore((state) => state.updateUser);
	const updateRegisterRedirectUrl = useAuthStore((state) => state.updateRegisterRedirectUrl);

	const [state, updateState, stateRef] = useRefImmer({
		showCreatePage: false,
		showContinueAsGuest: true,
		quizId: null,
		generated: false,
		editorial: props.editorial,
		editorialLoaded: !!props.editorial,
	});

	const playQuiz = usePlayQuiz(state.quizId);

	const setShowCreatePage = useCallback(
		(showCreatePage, quizId = null, generated = false, showContinueAsGuest = true, registerRedirectUrl = null) =>
			void updateState((draft) => {
				draft.showCreatePage = showCreatePage;
				draft.showContinueAsGuest = showContinueAsGuest;
				draft.quizId = quizId;
				draft.generated = generated;
				if (showCreatePage) {
					if (registerRedirectUrl) {
						console.log("SET!!", registerRedirectUrl);
						updateRegisterRedirectUrl(registerRedirectUrl);
					} else if (quizId) {
						updateRegisterRedirectUrl(`/play/${quizId}/`);
					} else {
						updateRegisterRedirectUrl(null);
					}
				} else {
					updateRegisterRedirectUrl(null);
				}
			}),
		[updateRegisterRedirectUrl, updateState]
	);

	useEffect(() => updateState((draft) => void (draft.editorial = props.editorial)), [props.editorial, updateState]);

	const [, fetchEditorialRef] = useRefCallback(
		(category = null, cacheBurst = false) => {
			updateState((draft) => void (draft.editorialLoaded = false));
			const { promise, abort } = apiEditorialByCategory2(category, cacheBurst ? { cacheburst: true } : null);
			promise
				.then((editorial) =>
					updateState((draft) => {
						draft.editorial = editorial;
						draft.editorialLoaded = !!editorial;
					})
				)
				.catch((error) => console.error(error));
			return abort;
		},
		[updateState]
	);

	useEffect(() => {
		if ((!stateRef.current.editorial || adminFeaturesEnabled) && fetchEditorialRef.current) {
			return fetchEditorialRef.current(null, adminFeaturesEnabled); // Abort
		}
	}, [adminFeaturesEnabled, fetchEditorialRef, stateRef]);

	useEffect(() => {
		const getUser = async () => {
			try {
				const user = await getUserSelf();
				updateUser(user);
			} catch (error) {
				console.error(error);
			}
		};

		let timeout = null;

		if (user && !user.isVerified) {
			timeout = setTimeout(() => {
				getUser();
			}, 15000);
		}

		return () => {
			if (timeout) {
				clearTimeout(timeout);
			}
		};
	}, [user, updateUser]);

	return (
		<>
			<NavigationLayout
				showFooter={state.editorialLoaded}
				showBigDiscordButton={true}
				navigationChildren={<NavigationCategories isIndexPage={true} />}
				mainChildren={
					<div className="md:pt-2 flex flex-col gap-4 pt-4">
						<Banner setShowCreatePage={setShowCreatePage} />
						<h1 className="pb-4 font-sans text-3xl font-black tracking-normal text-black sr-only">
							{trans("Quiz.com")}
						</h1>
						<Editorial
							editorial={state.editorial}
							showCategoryName={false}
							showTags={false}
							showLibrary={true}
							showBanners={true}
							setShowCreatePage={setShowCreatePage}
						/>
					</div>
				}
			/>
			{state.showCreatePage && !isAuthenticated && (
				<SignUpSignIn
					showContinueAsGuest={state.showContinueAsGuest}
					onCancel={(playQuizOnExit) => {
						setShowCreatePage(false);
						if (state.quizId && playQuizOnExit) {
							playQuiz();
						}
					}}
				/>
			)}
		</>
	);
}

function Banner({ setShowCreatePage = null }) {
	const router = useRouter();
	const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
	const user = useAuthStore((state) => state.user);
	const isGuestUser = useAuthStore((state) => state.isGuestUser);
	const setNewPlaySession = usePlaySessionStore((state) => state.setNewPlaySession);
	const isHostingActive = useWebSocketStore((state) => state.connected);

	const [name, setName] = useState(null);
	const [isTemporaryName, setIsTemporaryName] = useState(false);
	useIsomorphicLayoutEffect(() => {
		if (isAuthenticated && user) {
			const name = user.name;
			setName(name);
			setIsTemporaryName(name && name.indexOf("user_") === 0 && name.length === 25);
			return; // !!!
		}

		setName(null);
		setIsTemporaryName(false);
	}, []);

	const onClickQuizEditor = useCallback(() => {
		if (isAuthenticated) {
			router.push("/edit/new/");
		} else {
			if (setShowCreatePage) {
				setShowCreatePage(true, null, false, false, "/edit/new/");
			}
		}
	}, [isAuthenticated, router, setShowCreatePage]);

	const onClickQuizGenerator = useCallback(() => {
		if (isAuthenticated || isGuestUser) {
			if (!isHostingActive) {
				setNewPlaySession(true);
			}
			router.push("/play/generate/");
		} else {
			if (setShowCreatePage) {
				setShowCreatePage(true, "", false, false, "/play/generate/");
			}
		}
	}, [isAuthenticated, isGuestUser, isHostingActive, router, setNewPlaySession, setShowCreatePage]);

	return (
		<div className="md:flex-row flex flex-col gap-4">
			<div className="md:w-1/2 bg-petrol-dark rounded-xl md:p-8 lg:p-0 flex flex-row items-center justify-start w-full gap-8 p-4">
				<div className="aspect-square lg:block relative hidden w-5/12 ml-8">
					<img
						src="/images/banner/banner-illustration-create.svg"
						alt=""
						className="absolute inset-0 object-contain w-full h-full"
					/>
				</div>
				<div className="lg:w-7/12 lg:mr-8 lg:my-8 flex flex-col items-center justify-center w-full">
					<h2 className="md:text-4xl lg:text-3xl xl:text-4xl 2xl:text-5xl md:pb-0 pb-2 text-3xl font-black leading-none text-center text-white">
						{trans("Create a quiz")}
					</h2>
					<h3 className="md:text-base lg:text-xl flex flex-col items-center justify-center pb-4 text-sm font-bold leading-none text-center text-white">
						<div>{trans("Play for free with")}</div>
						<div>{trans("300 participants")}</div>
					</h3>
					<Button
						elementType="button"
						border={true}
						color="green-light"
						className="md:h-14 whitespace-nowrap md:px-6 md:w-auto md:py-0 px-6 font-bold text-white min-w-[7rem] md:min-w-[12rem]"
						onClick={onClickQuizEditor}
					>
						{trans("Quiz editor")}
					</Button>
				</div>
			</div>
			<div className="md:w-1/2 bg-petrol-dark rounded-xl md:p-0 flex flex-row items-center justify-start w-full gap-8 p-4">
				<div className="aspect-square lg:block relative hidden w-5/12 ml-8">
					<img
						src="/images/banner/banner-illustration-ai.svg"
						alt=""
						className="absolute inset-0 object-contain w-full h-full"
					/>
				</div>
				<div className="lg:w-7/12 lg:mr-8 lg:my-8 flex flex-col items-center justify-center w-full">
					<h2 className="md:text-4xl lg:text-3xl xl:text-4xl 2xl:text-5xl md:pb-0 pb-2 text-3xl font-black leading-none text-center text-white">
						{trans("A.I.")}
					</h2>
					<h3 className="md:text-base lg:text-xl flex flex-col items-center justify-center pb-4 text-sm font-bold leading-none text-center text-white">
						<div>{trans("Generate a quiz from")}</div>
						<div>{trans("any subject or pdf")}</div>
					</h3>
					<Button
						elementType="button"
						border={true}
						color="generator-blue"
						className="md:h-14 whitespace-nowrap md:px-6 md:w-auto md:py-0 px-6 font-bold text-black min-w-[7rem] md:min-w-[12rem]"
						onClick={onClickQuizGenerator}
					>
						{trans("Quiz generator")}
					</Button>
				</div>
			</div>
		</div>
	);
}

/*
function BannerOld({ setShowCreatePage = null }) {
	const router = useRouter();
	const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
	const user = useAuthStore((state) => state.user);
	const isGuestUser = useAuthStore((state) => state.isGuestUser);
	const setNewPlaySession = usePlaySessionStore((state) => state.setNewPlaySession);
	const isHostingActive = useWebSocketStore((state) => state.connected);

	const [name, setName] = useState(null);
	const [isTemporaryName, setIsTemporaryName] = useState(false);
	useIsomorphicLayoutEffect(() => {
		if (isAuthenticated && user) {
			const name = user.name;
			setName(name);
			setIsTemporaryName(name && name.indexOf("user_") === 0 && name.length === 25);
			return; // !!!
		}

		setName(null);
		setIsTemporaryName(false);
	}, []);

	const onClickQuizEditor = useCallback(() => {
		if (isAuthenticated) {
			router.push("/edit/new/");
		} else {
			if (setShowCreatePage) {
				setShowCreatePage(true, null, false, false, "/edit/new/");
			}
		}
	}, [isAuthenticated, router, setShowCreatePage]);

	const onClickQuizGenerator = useCallback(() => {
		if (isAuthenticated || isGuestUser) {
			if (!isHostingActive) {
				setNewPlaySession(true);
			}
			router.push("/play/generate/");
		} else {
			if (setShowCreatePage) {
				setShowCreatePage(true, "", false, false, "/play/generate/");
			}
		}
	}, [isAuthenticated, isGuestUser, isHostingActive, router, setNewPlaySession, setShowCreatePage]);

	return (
		<div className="md:flex-row flex flex-col gap-4">
			<div className="md:w-1/2 bg-black-10 rounded-xl md:p-8 lg:p-0 flex flex-row items-center justify-start w-full gap-8 p-4">
				<div className="lg:mr-8 lg:my-8 flex flex-col items-center justify-center w-full">
					<h2 className="md:text-base lg:text-xl flex flex-col items-center justify-center pb-4 text-sm font-bold leading-none text-center text-black text-opacity-50">
						<BannerOldWelcomeText name={name} isTemporaryName={isTemporaryName} />
					</h2>
					<Button
						elementType="button"
						border={true}
						color="green-lighter"
						className="md:h-14 whitespace-nowrap md:px-6 md:w-auto md:py-0 px-6 font-bold text-black"
						onClick={onClickQuizEditor}
					>
						{trans("Create a quiz")}
					</Button>
				</div>
			</div>
			<div className="md:w-1/2 bg-black-10 rounded-xl md:p-0 flex flex-row items-center justify-start w-full gap-8 p-4">
				<div className="lg:mr-8 lg:my-8 flex flex-col items-center justify-center w-full">
					<h2 className="md:text-base lg:text-xl flex flex-col items-center justify-center pb-4 text-sm font-bold leading-none text-center text-black text-opacity-50">
						{trans("Try our ChatGPT powered")}
					</h2>
					<Button
						elementType="button"
						border={true}
						color="generator-blue"
						className="md:h-14 whitespace-nowrap md:px-6 md:w-auto md:py-0 px-6 font-bold text-black"
						onClick={onClickQuizGenerator}
					>
						{trans("AI quiz generator")}
					</Button>
				</div>
			</div>
		</div>
	);
}

function BannerOldWelcomeText({ name, isTemporaryName }) {
	if (!name) {
		return <>{trans("Quiz.com is a free, gamebased learning platform")}</>;
	}

	if (isTemporaryName) {
		return (
			<>
				{trans("Welcome back, ")}
				<Link legacyBehavior href="/user/profile/" prefetch={false}>
					<a className="underline">{name}</a>
				</Link>
				{"!"}
			</>
		);
	}

	return <>{trans("Welcome back, %s!", name)}</>;
}
*/

import React, { useCallback, useEffect, useRef, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";

import onWindowResize from "@/helpers/onWindowResize";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import trans from "@/helpers/trans";

import useAuthStore from "@/stores/auth";
import useLayoutStore from "@/stores/layout";

import useRefMounted from "@/hooks/useRefMounted";

import BackgroundImageWrapper from "@/components/BackgroundImageWrapper";
import Footer from "@/components/Footer";
import Button from "@/components/interactives/Button";

export default function NavigationLayout({
	navigationChildren,
	mainChildren,
	showFooter = true,
	showBigDiscordButton = false,
}) {
	const router = useRouter();
	const [, mountedRef] = useRefMounted();

	const _authStoreUser = useAuthStore((state) => state.user);
	const _authStoreIsAuthenticated = useAuthStore((state) => state.isAuthenticated);
	const [user, setUser] = useState(null);
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	useEffect(() => void setUser(_authStoreUser), [_authStoreUser]);
	useEffect(() => void setIsAuthenticated(_authStoreIsAuthenticated), [_authStoreIsAuthenticated]);

	const mobileNavigationCategoriesVisible = useLayoutStore((state) => state.mobileNavigationCategoriesVisible);
	const setLayoutStore = useLayoutStore((state) => state.set);

	const navigationButtonContainerRef = useRef(null);
	const navigationContainerRef = useRef(null);

	const navigationChildrenOuterContainerRef = useRef(null);
	const navigationChildrenContainerRef = useRef(null);

	const onClickToggleNavigationVisible = useCallback(
		() =>
			void setLayoutStore(
				(draft) => void (draft.mobileNavigationCategoriesVisible = !draft.mobileNavigationCategoriesVisible)
			),
		[]
	);

	useEffect(() => {
		if (navigationButtonContainerRef.current && navigationContainerRef.current) {
			const resize = () => {
				if (navigationButtonContainerRef.current && navigationContainerRef.current) {
					const { offsetTop, offsetHeight } = navigationButtonContainerRef.current;
					navigationContainerRef.current.style.top = `${offsetTop + offsetHeight}px`;
				}
			};

			resize(); // Initial resize

			return onWindowResize(resize);
		}
	}, []);

	useEffect(() => {
		if (navigationChildrenOuterContainerRef.current && navigationChildrenContainerRef.current) {
			const resize = () => {
				if (navigationChildrenOuterContainerRef.current && navigationChildrenContainerRef.current) {
					const { offsetTop } = navigationChildrenOuterContainerRef.current;
					navigationChildrenContainerRef.current.style.top = `${offsetTop + 8}px`;
				}
			};

			resize(); // Initial resize

			return onWindowResize(resize);
		}
	}, []);

	return (
		<BackgroundImageWrapper>
			<button
				className={tailwindCascade(
					"z-1",
					"md:hidden",
					"fixed",
					"top-0",
					"left-0",
					"block",
					"w-full",
					"h-full",
					"bg-black",
					"bg-opacity-50",
					"cursor-default",
					{
						hidden: !mobileNavigationCategoriesVisible,
					}
				)}
				onClick={onClickToggleNavigationVisible}
			></button>
			<div className="flex flex-col flex-1 w-full">
				<div className="md:custom-container md:mx-auto md:min-h-full relative max-w-full py-0 md:left-[calc(50vw-50%)] px-4 md:px-0">
					<div className="relative flex flex-col w-full min-h-full">
						<div
							className={tailwindCascade("relative md:py-4 md:block", {
								hidden: !mobileNavigationCategoriesVisible,
							})}
						>
							<div className="md:relative bg-theme md:bg-transparent md:rounded-none md:p-0 rounded-2xl top-4 md:top-0 absolute z-10 flex w-full p-4">
								<div className="md:pb-0 relative flex flex-col items-center w-full pb-4">
									<div className="md:hidden flex flex-col items-center w-full pt-4">
										{isAuthenticated ? (
											<Link legacyBehavior href="/edit/new/" prefetch={false}>
												<Button
													onClick={onClickToggleNavigationVisible}
													elementType="a"
													border={3}
													color="green-lighter"
													className="whitespace-nowrap md:px-8 h-10 px-6 py-0 text-base font-bold"
												>
													{trans("Create new quiz")}
												</Button>
											</Link>
										) : (
											<Link legacyBehavior href="/user/login/" prefetch={false}>
												<Button
													onClick={onClickToggleNavigationVisible}
													elementType="a"
													border={3}
													color="green-lighter"
													className="whitespace-nowrap md:px-8 h-10 px-6 py-0 text-base font-bold"
												>
													{trans("Sign in")}
												</Button>
											</Link>
										)}
										<div className="w-full h-[1px] bg-black bg-opacity-20 my-8" />
									</div>
									{navigationChildren}
								</div>
							</div>
						</div>

						<div className="relative flex flex-col flex-1 w-full h-full">
							<div className="flex flex-col flex-1 w-full h-full">{mainChildren}</div>
							{showFooter && <Footer showBigDiscordButton={showBigDiscordButton} />}
						</div>
					</div>
				</div>
			</div>
		</BackgroundImageWrapper>
	);
}

import React from "react";

import { NextSeo } from "next-seo";

import { apiEditorialByCategory } from "@/api/editorial";
import { apiGetBannerStyle } from "@/api/settings";

import IndexPage from "@/components/pages/IndexPage";

import { withCacheHeader } from "@/helpers/cacheHeader";
import trans from "@/helpers/trans";

import { CACHE_HEADER_TTL } from "@/constants";

export const getServerSideProps = withCacheHeader(async () => {
	let editorial = null;
	try {
		editorial = await apiEditorialByCategory();
	} catch (error) {
		editorial = null;
	}

	/*
	let showOldBanner = false;
	try {
		showOldBanner = (await apiGetBannerStyle()) === "old";
	} catch (error) {
		showOldBanner = false;
	}
	*/

	return {
		props: { editorial, showOldBanner: true },
	};
}, CACHE_HEADER_TTL);

export default function Page({ editorial, showOldBanner }) {
	return (
		<>
			<NextSeo title={trans("Play & Create Quiz")} />
			<IndexPage editorial={editorial} showOldBanner={showOldBanner} />
		</>
	);
}
